import { Button, Dialog, DialogContent, Grid, Typography } from "@mui/material";
import { ColorButton, ColorOutlinedButton } from "../../hoc/PaletteComponent";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { CustomSelect, CustomTextField } from "../../hoc/CustomComponents";

const allVehicleType = ["BUS", "HD", "LMD"];
const allFuelType = ["DIESEL", "CNG"];
export default function AddVehicle({
  open,
  handleClose,
  setVin,
  vin,
  addMapping,
  imei,
  setImei,
  engineNo,
  setEngineNo
}) {
  const classes = useStyles();

  /**
   * @summary This handle the VIN value
   */
  const handleVIN = (e) => {
    setVin(e);
  };

  /**
   * @summary This handle the IMEI value
   */
  const handleIMEI = (e) => {
    setImei(e);
  };

  /**
  * @summary This handle the Engine no. value
  */
  const handleEngineNo = (e) => {
    setEngineNo(e);
  };

  const onKeyPress = (e) => {
    if (e.key === "Enter") {
      addMapping();
    }
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth={"md"}
        open={open}
        aria-labelledby="max-width-dialog-title"
        disableEscapeKeyDown={true}
      >
        <DialogContent>
          <form>
            <Grid container>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={9}>
                    <Typography variant="h4">VIN-IMEI Mapping</Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Button
                      style={{ float: "right" }}
                      onClick={() => handleClose()}
                    >
                      <CloseIcon
                        style={{
                          fill: "#2b2924",
                        }}
                      />
                    </Button>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <CustomTextField
                      value={vin}
                      label="VIN*"
                      placeholder="Vin"
                      onChange={(e) => handleVIN(e.target.value)}
                      // disabled={edit}
                      onKeyPress={onKeyPress}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      value={imei}
                      label="IMEI*"
                      placeholder="IMEI"
                      onChange={(e) => handleIMEI(e.target.value)}
                      onKeyPress={onKeyPress}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <CustomTextField
                      value={engineNo}
                      label="Engine No.*"
                      placeholder="Engine No."
                      onChange={(e) => handleEngineNo(e.target.value)}
                      onKeyPress={onKeyPress}
                    />
                  </Grid>
                </Grid>
                <Grid
                  container
                  justifyContent="flex-end"
                  style={{ paddingTop: "16px" }}
                >
                  <Grid
                    item
                    style={{ paddingRight: "24px", paddingBottom: "15px" }}
                    xs={3}
                  >
                    <ColorOutlinedButton
                      // className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={handleClose}
                    >
                      Cancel
                    </ColorOutlinedButton>
                  </Grid>
                  <Grid item xs={3}>
                    <ColorButton
                      // className={classes.buttonOutlined}
                      variant="outlined"
                      onClick={addMapping}
                      disabled={
                        vin == null ||
                        imei == null ||
                        engineNo == null ||
                        vin == "" ||
                        imei == "" ||
                        engineNo == ""
                      }
                    >
                      Submit
                    </ColorButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        {/* <DialogActions></DialogActions> */}
      </Dialog>
    </React.Fragment>
  );
}
